
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import InsertImage from  './lib/custom-btn'
import InsertDropDown from './lib/custom-drop';
import TemplateDrop from './lib/template-drop';
import SaveTemplateButton from './lib/save-template-btn'
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import OpenTemplate from './lib/open-template';
import MathType from '@wiris/mathtype-ckeditor5/src/plugin';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import { MyCustomUploadAdapterPlugin } from './lib/upload-adapter';
class Editor extends ClassicEditor {}
function SpecialCharactersExtended( editor ) {
	editor.plugins.get( 'SpecialCharacters' ).addItems( 'Indian', [
		{ title: '0', character: '٠' },
        { title: '1', character: '١' },
        { title: '2', character: '٢' },
        { title: '3', character: '٣' },
        { title: '4', character: '٤' },
        { title: '5', character: '٥' },
        { title: '6', character: '٦' },
        { title: '7', character: '٧' },
        { title: '8', character: '٨' },
        { title: '9', character: '٩' },
    ] );
}
// Plugins to include in the build.
Editor.builtinPlugins = [
	Alignment,
	Autoformat,
	BlockQuote,
	Bold,
	CKFinderUploadAdapter,
	CloudServices,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	HorizontalLine,
	Image,
	ImageCaption,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
	Indent,
	Italic,
	Link,
	List,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SpecialCharacters,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableToolbar,
	TextTransformation,
	Underline,
	InsertImage,
	InsertDropDown,
	TemplateDrop,
	SaveTemplateButton,
	ImageResizeEditing, 
	ImageResizeHandles,
	ImageResize,
	GeneralHtmlSupport,
	OpenTemplate,
	MathType,
	SpecialCharacters, 
	SpecialCharactersEssentials,
	SpecialCharactersExtended,
	MyCustomUploadAdapterPlugin
];
// Editor configuration.
Editor.defaultConfig = {
	htmlAllowedTags: ['.*'],
	htmlAllowedAttrs: ['.*'],
	fontFamily: {
		options: [
		  'default',
		  'Ubuntu, Arial, sans-serif',
		  'Ubuntu Mono, Courier New, Courier, monospace',
		  'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif',
		  'Tahoma/Tahoma, Geneva, sans-serif',
		  'Times New Roman/Times New Roman, Times, serif',
		  'Verdana/Verdana, Geneva, sans-serif',
		  'coHeadline',
		  'coHeadlineBold',
		  'coHeadlineRegular', 
		  'rumman',
		]
	  },
	  fontSize: {
		options: [8, 9,10,11,12,14,18,24,30,36 , 48 ],
		supportAllValues: true

	},
	toolbar: {
		shouldNotGroupWhenFull: true,

		items: [
			'undo', 'redo', '|', 'heading', '|',
			'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
			'bold', 'italic', 'strikeThrough', 'underline', 'subscript', 'superscript', '|',
			'-',
			'link', 'insertTable', 'blockQuote', 'HorizontalLine', '|',
			'outdent', 'indent', '|',
			'bulletedList', 'numberedList', '|', 'alignment', '|',
			'RemoveFormat', '|',
			'specialCharacters', 'ChemType','mathType','insertImage','InsertDropDown' ,'TemplateDrop','saveTemplateButton',
			'openTemplate'
		]
	},
	language: 'ar',
	allow: [
        {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
        },
		{
            name: 'div',
            attributes: {
                foo: 'true',
                bar: true
            }
        }
    ],
	image: {
		toolbar: [
			'imageStyle:inline',
			'imageStyle:block',
			'imageStyle:side',
			'imageTextAlternative',
			'resizeImage'
		],
		resizeUnit: 'px',
		resizeOptions: [
		  {
			name: 'resizeImage:original',
			value: null,
			icon: 'original'
		  },
		  {
			name: 'resizeImage:50',
			value: '50',
			icon: 'medium'
		  },
		  {
			name: 'resizeImage:25',
			value: '25',
			icon: 'medium'
		  },
		  {
			name: 'resizeImage:75',
			value: '75',
			icon: 'large'
		  }
		],
	}
};

export default Editor;
